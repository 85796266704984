<template>
  <div class="container-fluid">
    <div id="modalLabel" class="box-title">免责声明</div>
    <div class="divider"/>
    <div class="box-body">
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">在使用“必孚（中国）”前，请您务必仔细阅读并透彻理解本声明。您可以选择不使用，但如果您使用“必孚（中国）”，您的使用行为将被视为对本声明全部内容的认可。您明确并同意使用“必孚（中国）”所有服务所存在的风险将由您本人承担；因您使用“必孚（中国）”而产生的一切后果也由您本人承担，“必孚（中国）”对此不承担任何责任。除将来“必孚（中国）”另有其它明示规定，所有新推出的产品或新增加的服务功能，均适用本声明条款之规范。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-weight: bold;"><span>一、信息数据服务声明</span></div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">1.1 用户理解并同意，“必孚（中国）”致力于提供合理、准确、完整的信息数据，但不保证信息数据的合理性、准确性和完整性，且不因对信息的不合理、不准确或遗漏导致的任何损失或损害承担责任。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">1.2用户理解并同意，“必孚（中国）”不为您传播、散布“必孚（中国）”上的信息数据产生的结果负任何法律责任。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">1.3用户理解并同意，关于本协议服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">1.4用户理解并同意，“必孚（中国）”如因系统维护或升级而需要暂停服务时，将事先公告。若因线路及非本公司控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-weight: bold;"><span>二、必孚肉集服务声明</span></div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">2.1 用户理解并同意，“必孚（中国）”致力于为买卖双方提供贸易对接，但不保证成功对接。在交易过程中，“必孚（中国）”不参与买卖双方的资金往来和货物交割，不对买卖双方的资金安全和货物交割及品质作出保证，买卖双方应自行甄别整个交易过程的风险，“必孚（中国）”不承担任何责任以及不作任何赔付。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">2.2 用户理解并同意，用户在“必孚（中国）”平台发布的供求信息真实有效，在平台上的任何行为经由在职公司授权且代表在职公司，为在平台上的任何行为所产生的一切后果承担法律责任，“必孚（中国）”无法且不会对因用户行为而导致的任何损失或损害承担责任。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">2.3 用户理解并同意，“必孚（中国）”服务对象仅限于会员合同签订公司，其下属公司或关联公司均无法享受此增值服务。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">2.4 用户理解并同意，“必孚（中国）”并非司法机构，仅能以普通或非专业人员的知识水平标准对各方提交的材料信息进行鉴别，若买卖双方之间发生纠纷，必孚（中国）不参与交易纠纷的调处，也不承担任何责任及赔付。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-weight: bold;"><span>三、不可抗力及第三方免责声明</span></div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">3.1 用户理解并同意，在使用“必孚（中国）”软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，“必孚（中国）”将努力在第一时间及时修复，但因不可抗力给用户造成了损失，“必孚（中国）”将不承担任何责任。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">3.2 用户理解并同意，“必孚（中国）”的服务可能包括或链接至第三方提供的社交媒体或其他服务（包括网站）。例如：您利用"分享"键将某些内容分享到第三方的服务可能会收集您的相关信息（包括您的日志信息），并可能在您的设备装置 cookies，从而正常运行上述功能；“必孚（中国）”通过广告或“必孚（中国）”服务的其他方式向您提供链接，使您可以接入第三方的服务或网站。该等第三方社交媒体或其他服务可能由相关的第三方或“必孚（中国）”运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非《服务条款》或本政策）约束，您需要仔细阅读其条款。本政策仅适用于“必孚（中国）”所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，“必孚（中国）”对任何第三方使用由您提供的信息不承担任何责任；如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，“必孚（中国）”不承担任何责任。“必孚（中国）”不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);">本声明是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与国家司法、行政机关的判断一致、如因此产生的后果用户已经理解并同意自行承担。</div>
      <div style="color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-weight: bold;"><span>该《免责声明》最终解释权归必孚(成都)科技有限公司所有。</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CNPrivacyPolicy',
  components: {},
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.container-fluid {
  width: 1200px;
  min-height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 2048px) {
  .container-fluid {
    min-height: 835px;
  }
}

.box-title {
  text-align: center;
  color: #333333;
  font-size: 20px;
  margin-top: 40px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #F5F5F5;
  margin-top: 20px;
}

.box-body {
  margin-bottom: 20px;
}

.box-body div {
  margin: 10px 0;
  font-size: 14px;
  color: #666666;
}

.box-body div span {
  font-size: 16px;
}
</style>
